import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { User } from '../../model/user.model';
import { Outcome } from '../../model/outcome.model';
import { TranslateService } from '@ngx-translate/core';
import { Constants } from 'src/app/model/constants.model';
import { FormBuilder, FormGroup } from '@angular/forms';
import { AuthService } from './login.service';
import { BaseComponent } from 'src/app/common/base.component';
import { environment } from 'src/environments/environment';

@Component({
  selector: "app-dashboard",
  templateUrl: 'forgot-password.component.html'
})
export class ForgotPasswordComponent extends BaseComponent<null> {

  protected validForm = false;
  msgType: string;
  message: string;
  isRecaptchaValid = false;
  formGroup: FormGroup;
  siteKey = environment.siteKey;
  webVersion: string;
  
  constructor(private authService: AuthService, private formBuilder: FormBuilder, private route: Router
            , private translate: TranslateService) {
    super(new User(), null, formBuilder.group(new User()));
    this.webVersion = this.authService.getVersion();
    this.formGroup.patchValue({
       username: '',
       email: ''
    });
  }

  sendPassword() {
    if (this.formGroup.invalid) { this.validForm = false;  this.showMessage(Constants.FAILURE, this.translate.instant('msg.allRequired')); return; }
    const formData: FormData = new FormData();
    formData.append('username', this.formGroup.get('username').value);
    formData.append('email', this.formGroup.get('email').value);
    this.authService.passwordForgotten(formData).subscribe(response => this.showEsito(response));
  }

  private showEsito(outcome: Outcome<string>) {
    if (outcome.status === Constants.FAILURE) {
      this.showMessage(Constants.FAILURE,  outcome.message);
    } else {
      this.showMessage(Constants.SUCCESS, this.translate.instant('msg.sentNewPassword'));
    }
  }

  backToLogin() {
    this.route.navigate(['/login']);
  }
  public showResponse(event) {
    this.isRecaptchaValid = true;
  }
}
