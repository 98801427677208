import { Injectable } from '@angular/core';
import { HttpClient} from '@angular/common/http';
import { Outcome } from 'src/app/model/outcome.model';
import { map, catchError } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { Project } from 'src/app/model/project.model';
import { Stakeholder } from 'src/app/model/stakeholder.model';
import { User } from 'src/app/model/user.model';
import { CommonService } from 'src/app/common/common.service';

@Injectable()
export class ProjectService extends CommonService<Project> {

    constructor(protected httpClient: HttpClient) {
        super('project', httpClient);
    }

    get(id: number): Observable<Outcome<Project>> {
        return this.httpClient
        .get<Outcome<Project>>(this.targetUrl + '/get?id=' + id)
        .pipe(map(response => response)
            , catchError((error: any) => this.handleError(error))
      );
    }

    listStakeHolder(code: string, type: string): Observable<Outcome<Stakeholder[]>> {
        return this.httpClient
                .get<Outcome<Stakeholder[]>>(this.targetUrl + '/stakeholders/list?code=' + code + '&type=' + type)
                .pipe(map(response => response)
                    , catchError((error: any) => this.handleError(error))
              );
    }

    saveStakeHolder(projectCode: string, stakeholders: Stakeholder[]): Observable<Outcome<null>> {
        return this.httpClient
                .post(this.targetUrl + '/stakeholders/save?projectCode=' + projectCode , JSON.stringify(stakeholders), this.httpOptions)
                .pipe(map(response => response as Outcome<null>)
                    , catchError((error: any) => this.handleError(error))
      );
    }

    /*
    * @Deprecated
    */
    listUsers(code: string, type: string): Observable<Outcome<User[]>> {
        return this.httpClient
                .get<Outcome<User[]>>(this.targetUrl + '/users/list?code=' + code + '&type=' + type)
                .pipe(map(response => response)
                    , catchError((error: any) => this.handleError(error))
              );
    }

    /*
    * @Deprecated
    */
    saveUser(projectCode: string, users: User[]): Observable<Outcome<null>> {
        return this.httpClient
                .post(this.targetUrl + '/users/save?projectCode=' + projectCode , JSON.stringify(users), this.httpOptions)
                .pipe(map(response => response as Outcome<null>)
                    , catchError((error: any) => this.handleError(error))
      );
    }
}
