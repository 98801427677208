import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

/* Application components*/
import { LoginComponent } from './views/login/login.component';
//import { LoginOAuth2LoadingComponent } from './views/login/login-oauth2-loading.component';
import { MainLayoutComponent } from './views/layout/main-layout.component';
import { P404Component } from './views/common/404.component';
import { ForgotPasswordComponent } from './views/login/forgot-password..component';

import { AuthGuardService } from './common/auth-guard.service';
const routes: Routes = [
  { path: '', redirectTo: 'login', pathMatch: 'full' },
  { path: 'login', component: LoginComponent },
  { path: 'password/forgotten', component: ForgotPasswordComponent },
  //{ path: 'oauth2/callback', component: LoginOAuth2LoadingComponent},
  { path: 'home', component: MainLayoutComponent, canActivate: [AuthGuardService],
    children: [
      {
        path: 'admin',
        loadChildren: () => import('./views/admin/admin.module').then(m => m.AdminModule)
      },
      {
        path: 'data',
        loadChildren: () => import('./views/data/data.module').then(m => m.DataModule)
      },
      {
        path: 'map',
        loadChildren: () => import('./views/map/map.module').then(m => m.MapModule)
      }
    ]
  },
  { path: '**', component: P404Component }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {useHash : true, onSameUrlNavigation: 'reload'})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
