import { MapDomainValue } from '../../../model/map-domain-value.model';
import { map, catchError } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CommonService } from 'src/app/common/common.service';
import { Outcome } from 'src/app/model/outcome.model';

@Injectable()
export class MapDomainValueService extends CommonService<MapDomainValue> {

    constructor(protected httpClient: HttpClient) {
        super('map/entity/domain', httpClient);
    }

    getDomainHeader(idMea: string, stakeCode: string): Observable<Outcome<MapDomainValue>> {
        return this.httpClient
                   .get<Outcome<MapDomainValue>>(this.targetUrl + '/header/' + idMea + '?stakeCode=' + stakeCode)
                   .pipe(map(response => response)
                            , catchError((error: any) => this.handleError(error))
                    );
    }

    getDomainValues(idMea: string, stakeCode: string, page: number): Observable<Outcome<MapDomainValue[]>> {
        return this.httpClient
                   .get<Outcome<MapDomainValue[]>>(this.targetUrl + '/values/list/' + idMea + '?stakeCode=' + stakeCode + '&currentPage=' + page)
                   .pipe(map(response => response)
                            , catchError((error: any) => this.handleError(error))
                    );
    }

    insertMapDomainValue(mapDomainValues: MapDomainValue): Observable<Outcome<null>> {
        return this.httpClient
                .post(this.targetUrl + '/value/insert', JSON.stringify(mapDomainValues), this.httpOptions)
                .pipe(map(response => response as Outcome<null>)
                    , catchError((error: any) => this.handleError(error))
      );
    }

    updateMapDomainValue(mapDomainValues: MapDomainValue): Observable<Outcome<null>> {
        return this.httpClient
                .post(this.targetUrl + '/value/update', JSON.stringify(mapDomainValues), this.httpOptions)
                .pipe(map(response => response as Outcome<null>)
                    , catchError((error: any) => this.handleError(error))
      );
    }

    deleteMapDomainValue(mapDomainValues: MapDomainValue): Observable<Outcome<null>> {
        return this.httpClient
                .post(this.targetUrl + '/value/delete', JSON.stringify(mapDomainValues), this.httpOptions)
                .pipe(map(response => response as Outcome<null>)
                    , catchError((error: any) => this.handleError(error))
      );
    }

    getFile(idMea: string): Observable<Outcome<any>> {
        return this.httpClient
                   .get(this.targetUrl + '/download/' +  idMea)
                   .pipe(map((response) => response as Outcome<any>)
                          , catchError((error: any) => this.handleError(error))
                      );
    }

    getFileOutcomes(uploadedFileId: string): Observable<Outcome<any>> {
        return this.httpClient
                   .get(this.targetUrl + '/download_outcomes/' +  uploadedFileId)
                   .pipe(map((response) => response as Outcome<any>)
                          , catchError((error: any) => this.handleError(error))
                      );
    }

    uploadFile(formData: FormData, idMea: string, stakeCode: string): Observable<Outcome<null>> {
        return this.httpClient
            .post(this.targetUrl + '/upload/' + idMea + '?stakeCode=' + stakeCode, formData)
            .pipe(map((response) => response as Outcome<null>)
                      , catchError((error: any) => this.handleError(error))
                  );
    }

    confirmUpload(idUpload: string): Observable<Outcome<null>> {
        return this.httpClient
            .post(this.targetUrl + '/upload/confirm?idUpload=' +  idUpload, null)
            .pipe(map((response) => response as Outcome<null>)
                      , catchError((error: any) => this.handleError(error))
                  );
    }

    revertUpload(idUpload: string): Observable<Outcome<null>> {
        return this.httpClient
            .post(this.targetUrl + '/upload/revert?idUpload=' +  idUpload, null)
            .pipe(map((response) => response as Outcome<null>)
                      , catchError((error: any) => this.handleError(error))
                  );
    }

}
