import { MapDomainValue } from '../../../model/map-domain-value.model';
import { map, catchError } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CommonService } from 'src/app/common/common.service';
import { Outcome } from 'src/app/model/outcome.model';
import { AttributeType } from 'src/app/model/attribute-type.model';
import { MapEntityAttribute } from 'src/app/model/map-entity-attribute.model';
import { MapActivity } from 'src/app/model/map-activity.model';
import { Entity } from 'src/app/model/entity.model';
import { environment } from 'src/environments/environment';
import { MapSummary } from 'src/app/model/map-summary.model';

@Injectable()
export class MapEntityService extends CommonService<MapDomainValue> {

    constructor(protected httpClient: HttpClient) {
        super('map/entity', httpClient);
    }

    listOrderedEntities(formData: FormData): Observable<Outcome<Entity[]>> {
        return this.httpClient
                .post<Outcome<Entity[]>>(this.targetUrl + '/ordered/lov', formData)
                .pipe(map(response => response)
                    , catchError((error: any) => this.handleError(error))
            );
    }

    saveEntity(mapActivity: MapActivity): Observable<Outcome<null>> {
        return this.httpClient
                .post(this.targetUrl + '/save' , JSON.stringify(mapActivity), this.httpOptions)
                .pipe(map(response => response as Outcome<null>)
                    , catchError((error: any) => this.handleError(error))
      );
    }

    listAttributeDomainTypes(code: string): Observable<Outcome<AttributeType[]>> {
        return this.httpClient
                    .post<Outcome<AttributeType[]>>(this.targetUrl + '/lov?domainName=' + code, null)
                    .pipe(map(response => response)
                                , catchError((error: any) => this.handleError(error))
        );
    }

    listMapEntityAttributes(stakeCode: string, formData: FormData): Observable<Outcome<MapEntityAttribute[]>> {
        return this.httpClient
                .post<Outcome<MapEntityAttribute[]>>(this.targetUrl + '/attributes/list/' + stakeCode, formData)
                .pipe(map(response => response)
                    , catchError((error: any) => this.handleError(error))
              );
    }

    getSavedConfig(formData: FormData): Observable<Outcome<MapEntityAttribute[]>> {
        return this.httpClient
                .post<Outcome<MapEntityAttribute[]>>(this.targetUrl + '/get/saved/config', formData)
                .pipe(map(response => response)
                    , catchError((error: any) => this.handleError(error))
              );
    }

    checkMapActivity(activityId: string, stakeCode: string) {
        return this.httpClient
                   .get<Outcome<Entity[]>>(this.targetUrl + '/checkMapActivity?activityId=' + activityId + '&stakeCode=' + stakeCode)
                   .pipe(map(response => response)
                   , catchError((error: any) => this.handleError(error))
             );
    }

    generateXMLActivity(projectCode: string, activityName: string, stakeCode: string): Observable<Outcome<string>> {
        return this.httpClient
                   .post<Outcome<string>>(this.targetUrl + '/generateSchema?projectCode=' + projectCode + '&activityName=' + activityName + '&stakeCode=' + stakeCode, null)
                   .pipe(map(response => response)
                            , catchError((error: any) => this.handleError(error))
                    );
    }

    showXMLActivity(xmlType: string, projectCode: string, activityName: string, stakeCode: string): Observable<Outcome<string>> {
        return this.httpClient
                   .post<Outcome<string>>(this.targetUrl + '/showSchema?xmlType='+xmlType+'&projectCode=' + projectCode + '&activityName=' + activityName + '&stakeCode=' + stakeCode, null)
                   .pipe(map(response => response)
                            , catchError((error: any) => this.handleError(error))
                    );
    }
/*
    showFlatSchema(projectCode: string, activityName: string, stakeCode: string): Observable<Outcome<string>> {
        return this.httpClient
                   .post<Outcome<string>>(this.targetUrl + '/showFlatSchema?projectCode=' + projectCode + '&activityName=' + activityName + '&stakeCode=' + stakeCode, null)
                   .pipe(map(response => response)
                            , catchError((error: any) => this.handleError(error))
                    );
    }
*/
    showXSDActivity(xmlType: string, projectCode: string, activityName: string, stakeCode: string): Observable<Outcome<string>> {
        return this.httpClient
                   .post<Outcome<string>>(this.targetUrl + '/showXSDSchema?xmlType='+xmlType+'&projectCode=' + projectCode + '&activityName=' + activityName + '&stakeCode=' + stakeCode, null)
                   .pipe(map(response => response)
                            , catchError((error: any) => this.handleError(error))
                    );
    }

    checkXMLSchema(projectCode: string, activityName: string, stakeCode: string): Observable<Outcome<string>> {
        return this.httpClient
                   .get<Outcome<string>>(this.targetUrl + '/checkXMLSchema?projectCode=' + projectCode + '&activityName=' + activityName + '&stakeCode=' + stakeCode)
                   .pipe(map(response => response)
                            , catchError((error: any) => this.handleError(error))
                    );
    }

    deleteMappingActivity(projectCode: string, activityName: string, stakeCode: string): Observable<Outcome<null>> {
        return this.httpClient
                   .post<Outcome<null>>(this.targetUrl + '/deleteMapActivity?projectCode=' + projectCode + '&activityName=' + activityName + '&stakeCode=' + stakeCode, null)
                   .pipe(map(response => response)
                            , catchError((error: any) => this.handleError(error))
                    );
    }

    getTemplateMapXLS(projectCode: string, activityName: string, stakeCode: string): any {
        const httpOptions = {
                                'responseType'  : 'arraybuffer' as 'json'
                            };
        return this.httpClient
                   .get(this.targetUrl + '/activity/getTemplateXLS?projectCode=' + projectCode + '&activityName=' + activityName + '&stakeCode=' + stakeCode, httpOptions);
    }

    getMappingXLS(projectCode: string, activityName: string, stakeCode: string): any {
        const httpOptions = {
                                'responseType'  : 'arraybuffer' as 'json'
                            };
        return this.httpClient
                   .get(environment.apiUrl + '/map/upload/activity/getMappingXLS?projectCode=' + projectCode + '&activityName=' + activityName + '&stakeCode=' + stakeCode, httpOptions);
    }

    uploadMapping(formData: FormData, projectCode: string, activityName: string, stakeCode: string): Observable<Outcome<null>> {
        return this.httpClient
            .post(environment.apiUrl + '/map/upload/mapping/' + projectCode + '?activityName=' + activityName + '&stakeCode=' + stakeCode , formData)
            .pipe(map((response) => response as Outcome<null>)
                      , catchError((error: any) => this.handleError(error))
                  );
    }

    getTableActivity(projectCode: string, activityName: string, stakeCode: string): Observable<Outcome<MapSummary[]>> {
        return this.httpClient
            .get(this.targetUrl + '/activity/get/table/' + projectCode + '?activityName=' + activityName + '&stakeCode=' + stakeCode )
            .pipe(map(response => response as Outcome<MapSummary[]>)
                    , catchError((error: any) => this.handleError(error))
                );
    }
    
}
