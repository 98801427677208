import { BaseComponent } from 'src/app/common/base.component';
import { User } from 'src/app/model/user.model';
import { Component } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { Constants } from 'src/app/model/constants.model';
import { UserService } from '../admin/user/user.service';
import { ChangePassword } from '../admin/user/change-password.model';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';


@Component({
    templateUrl: 'change-password.component.html'
})
export class ChangePasswordComponent extends BaseComponent<UserService> {

    user: User;
    strongPattern: RegExp = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{12,})/;

    constructor(private formBuilder: FormBuilder, private userService: UserService, private translate: TranslateService
               , protected ref: DynamicDialogRef, protected config: DynamicDialogConfig ) {
        super(new ChangePassword(), userService, formBuilder.group(new ChangePassword()));
        this.formGroup.patchValue({
            username: config.data.username,
            password: '',
            confirmPassword: ''
          });
    }

    onSubmit() {
        if (this.formGroup.invalid) { this.validForm = false; return; }
        if (this.formGroup.get('password').value !== this.formGroup.get('confirmPassword').value) {
            this.showMessage(Constants.FAILURE, this.translate.instant('msg.newConfirmPasswordDifferent'));
            return false;
        }
        if(!this.strongPattern.test((this.formGroup).get('password').value)){
            this.showMessage(Constants.FAILURE, this.translate.instant('msg.notStrongPassword'));
            return false;
        }
        this.userService.changePassword(Object.assign({}, this.formGroup.value)).subscribe( response => {
            if (response.status === Constants.SUCCESS)
                this.ref.close();
            else
                this.showResult(response)
        });
    }
}
