import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { CommonService } from 'src/app/common/common.service';
import { MapDomainValue } from 'src/app/model/map-domain-value.model';
import { Outcome } from 'src/app/model/outcome.model';
import { environment } from 'src/environments/environment';

@Injectable()
export class TranslateFileService extends CommonService<MapDomainValue> {

    constructor(protected httpClient: HttpClient) {
        super('map/translate', httpClient);
    }

    getMaxFileSize() {
        return this.httpClient
                   .get(this.targetUrl + '/upload/file/maxfilesize')
                   .pipe(map((response) => response as Outcome<any[]>)
                          , catchError((error: any) => this.handleError(error))
                      );
    }

    checkFile(idUpload: string): Observable<Outcome<null>> {
        return this.httpClient
            .post(this.targetUrl + '/upload/xls/check/' +  idUpload, null)
            .pipe(map((response) => response as Outcome<null>)
                      , catchError((error: any) => this.handleError(error))
                  );
    }

    translateFile(idUpload: string, type: string): Observable<Outcome<null>> {
        return this.httpClient
            .post(this.targetUrl + '/upload/xls/schedule/' +  idUpload + "?outputType=" + type, null)
            .pipe(map((response) => response as Outcome<null>)
                      , catchError((error: any) => this.handleError(error))
                  );
    }

    revertUpload(idUpload: string): Observable<Outcome<null>> {
        return this.httpClient
            .post(this.targetUrl + '/upload/xls/revert/' +  idUpload, null)
            .pipe(map((response) => response as Outcome<null>)
                      , catchError((error: any) => this.handleError(error))
                  );
    }

    getFile(idUpload: string): Observable<Outcome<any[]>> {
        return this.httpClient
                   .get(this.targetUrl + '/download_esito_check/' +  idUpload)
                   .pipe(map((response) => response as Outcome<any[]>)
                          , catchError((error: any) => this.handleError(error))
                      );
    }

    traslateXmlData(projectCode: string, activityName: string, stakeCode: string, stakeXml: string) {
        const httpOptions = {
            headers: new HttpHeaders({'Content-Type': 'text/plain'})
            };
        return this.httpClient
                   .post<Outcome<string>>(environment.apiUrl + '/activity/traslateXmlData?projectCode=' + projectCode + '&activityName=' + activityName + '&stakeCode=' + stakeCode, stakeXml, httpOptions)
                   .pipe(map(response => response)
                   , catchError((error: any) => this.handleError(error))
           );

    }

    uploadFileExcel(formData: FormData, projectCode: string, activityName: string, stakeCode: string): Observable<Outcome<null>> {
        return this.httpClient
            .post(this.targetUrl + '/upload/file/' + projectCode + '?activityName=' + activityName + '&stakeCode=' + stakeCode , formData)
            .pipe(map((response) => response as Outcome<null>)
                      , catchError((error: any) => this.handleError(error))
                  );
    }

}