import { NgModule } from '@angular/core';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import {
  AppAsideModule,
  AppBreadcrumbModule,
  AppHeaderModule,
  AppFooterModule,
  AppSidebarModule,
} from '@coreui/angular';

import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { ModalModule } from 'ngx-bootstrap/modal';
/* i18n Modules */
import {TranslateModule, TranslateLoader} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';

/* Application Components */
import { LoginComponent } from './views/login/login.component';
//import { LoginOAuth2LoadingComponent } from './views/login/login-oauth2-loading.component';
import { MainLayoutComponent } from './views/layout/main-layout.component';
import { TopMenuComponent } from './views/layout/top-menu/top-menu.component';
import { P404Component } from './views/common/404.component';
import { UserInfoComponent } from './views/layout/user-info.component';
import { ForgotPasswordComponent } from './views/login/forgot-password..component';
import { LoadingComponent } from './views/common/loading.component';
import { ChangePasswordComponent } from './views/login/change-password.component';
/** Application Service */
import { AuthService } from './views/login/login.service';
import { AuthGuardService } from './common/auth-guard.service';
import { ProjectService } from './views/admin/project/project.service';
import { StakeholderService } from './views/admin/stakeholder/stakeholder.service';
import { DataConverter } from './common/data-converter.service';
import { DomainService } from './views/data/domain/domain.service';
import { DomainValueService } from './views/data/domain/domain-value.service';
import { MapDomainValueService } from './views/map/domain/map-domain-value.service';
import { UserService } from './views/admin/user/user.service';
import { MapEntityService } from './views/map/entity/map-entity.service';
import { UserProfileService } from './views/layout/user-profile.service';
import { LoaderService } from './views/common/loading.service';
import { TranslateFileService } from './views/map/translation/translate-file.service';
import { DragService } from './views/map/drag/drag.service';
import { UserMessageService } from './views/admin/user/user-message.service';
import { ControlPanelService } from './views/admin/controlPanel/controlPanel.service'; 
import { SupportService } from './views/admin/controlPanel/support.service';

import { User } from './model/user.model';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

/** primeNg */
import { MessageService } from 'primeng/api';
import { CaptchaModule } from 'primeng/captcha';
import { DynamicDialogModule } from 'primeng/dynamicdialog';
import { ToastModule } from 'primeng/toast';
import { PasswordModule } from 'primeng/password';
import { InputTextModule } from 'primeng/inputtext';
import { PanelModule } from 'primeng/panel';
import { ScrollPanelModule } from 'primeng/scrollpanel';
import { DragDropModule } from "primeng/dragdrop";
import { PaginationModule } from 'ngx-bootstrap/pagination';
import { AlertModule } from 'ngx-bootstrap/alert';

import { JwtInterceptor } from './common/jwt.interceptor';
import { SessionInterceptor } from './common/session.interceptor';
import { LoaderInterceptor } from './common/loader.interceptor';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { WelcomeComponent } from './views/layout/welcome.component';
import { ConfirmDeactivateGuard } from './common/conf-deact-guard.service';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, 'assets/i18n/', '.json');
}

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    //LoginOAuth2LoadingComponent,
    MainLayoutComponent,
    P404Component,
    TopMenuComponent,
    UserInfoComponent,
    ForgotPasswordComponent,
    LoadingComponent,
    ChangePasswordComponent,
    WelcomeComponent
  ],
  imports: [
    AppRoutingModule,
    AppAsideModule,
    AppBreadcrumbModule.forRoot(),
    AppFooterModule,
    AppHeaderModule,
    AppSidebarModule,
    PerfectScrollbarModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    CaptchaModule,
    ToastModule,
    PasswordModule,
    InputTextModule,
    PanelModule,
    ScrollPanelModule,
    DragDropModule,
    DynamicDialogModule,
    AlertModule.forRoot(),
    PaginationModule.forRoot(),
    ModalModule.forRoot(),
    BrowserAnimationsModule,
    TranslateModule.forRoot({
      loader: {
                  provide: TranslateLoader,
                  useFactory: HttpLoaderFactory,
                  deps: [HttpClient]
      }
    }),
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000'
    })
  ],
  providers: [
    AuthService,
    AuthGuardService,
    ConfirmDeactivateGuard,
    ProjectService,
    StakeholderService,
    UserService,
    UserMessageService,
    DomainService,
    DomainValueService,
    MapDomainValueService,
    MapEntityService,
    TranslateFileService,
    DragService,
    UserProfileService,
    ControlPanelService,
    SupportService,
    MessageService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: JwtInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: SessionInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LoaderInterceptor,
      multi: true
    },
    User,
    HttpClient,
    DataConverter,
    LoaderService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
