import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Outcome } from 'src/app/model/outcome.model';
import { map, catchError } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { Domain } from 'src/app/model/domain.model';
import { DomainValue } from 'src/app/model/domain-value.model';
import { CommonService } from 'src/app/common/common.service';
import { LazyLoadEvent } from 'primeng/api';



@Injectable()
export class DomainService extends CommonService<Domain> {


    constructor(protected httpClient: HttpClient) {
        super('domain', httpClient);
    }

    get(id: number): Observable<Outcome<Domain>> {
        return this.httpClient
                   .get<Outcome<Domain>>(this.targetUrl + '/get?id=' + id)
                   .pipe(map(response => response)
                        , catchError((error: any) => this.handleError(error))
                );
    }

    getMaxFileSize() {
        return this.httpClient
                   .get(this.targetUrl + '/upload/maxfilesize')
                   .pipe(map((response) => response as Outcome<any[]>)
                          , catchError((error: any) => this.handleError(error))
                      );
    }

    listDomainValues(code: string, stakeCode: string, tableOrder: LazyLoadEvent): Observable<Outcome<DomainValue[]>> {
        let formData = new FormData();
        formData.append('stakeCode', stakeCode);
        formData.append('tableOrder', JSON.stringify(tableOrder));
        return this.httpClient
                .post<Outcome<DomainValue[]>>(this.targetUrl + '/values/list?domainCode=' + code, formData)
                .pipe(map(response => response)
                    , catchError((error: any) => this.handleError(error))
              );
    }


   saveDomainValue(domainCode: string, domainValues: DomainValue[]): Observable<Outcome<null>> {
        return this.httpClient
                .post(this.targetUrl + '/value/save?domainCode=' + domainCode , JSON.stringify(domainValues), this.httpOptions)
                .pipe(map(response => response as Outcome<null>)
                    , catchError((error: any) => this.handleError(error))
      );
    }


    insertDomainValue(formData: FormData): Observable<Outcome<null>> {
        return this.httpClient
            .post(this.targetUrl + '/insert', formData)
            .pipe(map((response) => response as Outcome<null>)
                      , catchError((error: any) => this.handleError(error))
                  );
    }

    uploadFile(formData: FormData): Observable<Outcome<null>> {
        return this.httpClient
            .post(this.targetUrl + '/upload', formData)
            .pipe(map((response) => response as Outcome<null>)
                      , catchError((error: any) => this.handleError(error))
                  );
    }

    confirmUpload(idUpload: string): Observable<Outcome<null>> {
        return this.httpClient
            .post(this.targetUrl + '/upload/confirm?idUpload=' +  idUpload, null)
            .pipe(map((response) => response as Outcome<null>)
                      , catchError((error: any) => this.handleError(error))
                  );
    }

    revertUpload(idUpload: string): Observable<Outcome<null>> {
        return this.httpClient
            .post(this.targetUrl + '/upload/revert?idUpload=' +  idUpload, null)
            .pipe(map((response) => response as Outcome<null>)
                      , catchError((error: any) => this.handleError(error))
                  );
    }

   getFile(code: string, stakeCode: string): Observable<Outcome<any[]>> {
        return this.httpClient
                   .get(this.targetUrl + '/download/' +  code + '?stakeCode=' + stakeCode)
                   .pipe(map((response) => response as Outcome<any[]>)
                          , catchError((error: any) => this.handleError(error))
                      );
    }

}
