
export class Constants {

    static readonly INSERT: string = 'I';
    static readonly EDIT: string = 'E';
    static readonly UPDATE: string = 'U';
    static readonly DELETE: string = 'D';
    static readonly SHOW: string = 'S';
    static readonly FIND: string = 'F';
    static readonly SUCCESS: string = 'INFO';
    static readonly WARNING: string = 'WARN';
    static readonly FAILURE: string = 'ERROR';
    static readonly MsgDANGER: string = 'danger';
    static readonly MsgSUCCESS: string = 'success';
    static readonly MsgWARNING: string = 'warning';
    static readonly CATALOGUE: string = 'CATALOGUE';
    static readonly TYPE_COMPLEX: string = 'COMPLEX';
    static readonly TYPE_SIMPLE: string = 'SIMPLE';
    static readonly TYPE_MULTIPLE: string = 'MULTIPLE';
    static readonly TYPE_NUMBER: string = 'N';
    static readonly TYPE_NUMBER_EXT: string = 'NUMBER';
    static readonly TYPE_VARCHAR: string = 'V';
    static readonly TYPE_VARCHAR_EXT: string = 'VARCHAR';
    static readonly TYPE_NOT_USED: string = 'NOTUSED';
    static readonly COLUMN_TYPE_HEADER: string = 'H';
    static readonly FILE_TYPE_XML: string = 'XML';
    static readonly FILE_TYPE_XLS: string = 'XLS';
    static readonly FILE_ACT_TYPE: string = 'TEST_ACTIVITY';
    static readonly FILE_TYPE_FLAT_XML: string = 'FLAT-XML';
    static readonly FILE_LOG_TYPE_XLS: string = "XLS";
    static readonly FILE_LOG_TYPE_PDF: string = "PDF";
    static readonly notUsed: string = "notUsed";
    static readonly inherit: string = "inherit";
    static readonly YES: string = "Y";
    static readonly NO: string = "N";
    static readonly STATUS_NEW: string = "status.new";
    static readonly STATUS_PROGRESS: string = "status.progress";
    static readonly STATUS_REOPENED: string = "status.re-opened";
    static readonly STATUS_WAITING: string = "status.waiting";
    static readonly STATUS_SOLVED: string = "status.solved";
    static readonly STATUS_FEEDBACK: string = "status.feedback";
    static readonly STATUS_CLOSED: string = "status.closed";
    static readonly STATUS_REJECTED: string = "status.rejected";
    static readonly STATUS_APPROVED: string = "status.approved";
    static readonly STATUS_NOTAPPROVED: string = "status.notApproved";
    static readonly RECORD_ID: string = "recordId";
    
}
    
