import { Model } from './common.model';

export class SearchUser extends Model {

    username = '';
    header = '';
    email = '';
    active = '';
    defaultCountryCode = '';
    rolesName = '';
}
