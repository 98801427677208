import { Model } from 'src/app/model/common.model';


export class ChangePassword extends Model {

    header = '';
    username = '';
    oldPassword = '';
    password = '';
    confirmPassword = '';

}
