import { OnDestroy, Component, OnInit } from '@angular/core';
import { Menu } from 'src/app/model/menu.model';
import { User } from 'src/app/model/user.model';
import { UserService } from '../admin/user/user.service';
import { Outcome } from 'src/app/model/outcome.model';
import { NewsMessage } from 'src/app/model/news-message.model';

@Component({
    selector: 'app-main',
    templateUrl: './main-layout.component.html'
})
export class MainLayoutComponent implements OnDestroy, OnInit {

  public navItems: Menu[];
  public sidebarMinimized = true;
  public mainUser = JSON.parse(sessionStorage.getItem('currentUser')) as User;

  showDefaultMessage = true; // initial message state
  alertMessage: NewsMessage;
  displayAlert = false;


  constructor(private userService: UserService) {
    this.showDefaultMessage = true;
    this.getAlertMessage();
  }

  ngOnInit(): void {
    this.userService.getMenu().subscribe( (outcome: Outcome<any>) => {
                    const tempJson = JSON.parse(outcome.responseBody);
                    this.navItems = tempJson as Menu[];
                });
  }

  ngOnDestroy(): void {}
  
  getAlertMessage() {
    this.userService.getAlertMessage(this.mainUser.defaultProjectCode).subscribe(outcome => {
                    if (outcome.responseBody !== null) {
                      this.alertMessage = outcome.responseBody;
                      this.displayAlert = true;
                    } else {
                      this.alertMessage = new NewsMessage();
                    }
                });
  }

  toggleDefaultMessage(state: boolean) {
    this.showDefaultMessage = state;
  }
  
  setAlertRed() {
    this.userService.swithOffAlertMessage(this.alertMessage).subscribe(outcome => console.log(outcome));
  }
  
}
