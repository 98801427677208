import { Component, OnDestroy, ViewChild, AfterViewInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { LoaderService } from './loading.service';
import { LoaderState } from './loader-state.interface';
declare var $: any;

@Component({
    selector: 'app-loading',
    templateUrl: './loading.component.html'
})

export class LoadingComponent implements AfterViewInit, OnDestroy {

  @ViewChild('loadingModal', { static: true }) loadingModal;
  private subscription: Subscription;

  constructor(private loaderService: LoaderService) { }

  ngAfterViewInit() {
    this.subscription = this.loaderService.loaderState
        .subscribe((state: LoaderState) => {
          if (state.show) {
            this.loadingModal.show();
          } else {
            this.loadingModal.hide();
          }
          $('body').removeAttr('style');
        });
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
