import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { CommonService } from "src/app/common/common.service";
import { DbParameter } from "src/app/model/db-parameter.model";
import { Outcome } from "src/app/model/outcome.model";
import { TraceAccess } from "src/app/model/trace-access.model";
import { NewsMessage } from "src/app/model/news-message.model";
import { GlobalFilterLazyLoadEvent } from "src/app/model/global-filter-lazyload-event.model";

@Injectable()
export class ControlPanelService extends CommonService<DbParameter>{

    constructor(protected httpClient: HttpClient){
        super('control-panel', httpClient);
    }

    getListTraceAccess(tableOrder: GlobalFilterLazyLoadEvent): Observable<Outcome<TraceAccess[]>> {
        let formData = new FormData();
        formData.append('tableOrder', JSON.stringify(tableOrder));
        return this.httpClient
                .post<Outcome<TraceAccess[]>>(this.targetUrl + '/getListTraceAccess', formData)
                .pipe(map(response => response as Outcome<null>)
                    , catchError((error: any) => this.handleError(error))
                );
    }
    
    getListParam(tableOrder: GlobalFilterLazyLoadEvent): Observable<Outcome<DbParameter[]>> {
        let formData = new FormData();
        formData.append('tableOrder', JSON.stringify(tableOrder));
        return this.httpClient
                .post<Outcome<DbParameter[]>>(this.targetUrl + '/getListParam', formData)
                .pipe(map(response => response as Outcome<null>)
                    , catchError((error: any) => this.handleError(error))
                );
    }

    getAllNewsMessages(tableOrder: GlobalFilterLazyLoadEvent, type: string, projectCode : string): Observable<Outcome<NewsMessage[]>> {
        let formData = new FormData();
        formData.append('tableOrder', JSON.stringify(tableOrder));
        return this.httpClient
            .post<Outcome<NewsMessage>>(this.targetUrl + '/getAllNewsMessages?type=' + type + '&projectCode=' + projectCode, formData )
                .pipe(map(response => response as Outcome<null>)
                    , catchError((error: any) => this.handleError(error))
                );
    }

    insertNewsMessage(newsMessage: NewsMessage): Observable<Outcome<null>> {
        return this.httpClient
                .post(this.targetUrl + '/insertNewsMessage', JSON.stringify(newsMessage), this.httpOptions)
                .pipe(map(response => response as Outcome<null>)
                    , catchError((error: any) => this.handleError(error))
                );
    }
    
    updateNewsMessage(newsMessage: NewsMessage): Observable<Outcome<null>> {
        return this.httpClient
                .post(this.targetUrl + '/updateNewsMessage', JSON.stringify(newsMessage), this.httpOptions)
                .pipe(map(response => response as Outcome<null>)
                    , catchError((error: any) => this.handleError(error))
                );
    }

    deleteNewsMessage(idNewsMessage: number): Observable<Outcome<null>> {
        return this.httpClient
                .post(this.targetUrl + '/deleteNewsMessage?idNewsMessage=' + idNewsMessage, this.httpOptions)
                .pipe(map(response => response as Outcome<null>)
                    , catchError((error: any) => this.handleError(error))
                );
    }
}