import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { DialogService } from 'primeng/dynamicdialog';

import { ChangePasswordComponent } from './change-password.component';

import { AuthService } from './login.service';

import { User } from '../../model/user.model';
import { Outcome } from '../../model/outcome.model';
import { Constants } from 'src/app/model/constants.model';

@Component({
  selector: 'app-dashboard',
  templateUrl: 'login.component.html', //templateUrl: 'login-oauth2.component.html',
  providers: [DialogService]
})

export class LoginComponent implements OnInit {

  model: any = {};
  user: User = new User();
  show = false;
  responseMessage: string;
  webVersion: string;

  constructor(private authService: AuthService, private route: Router, private translate: TranslateService, public dialogService: DialogService) {  }

  ngOnInit() {
    this.responseMessage = '';
    this.webVersion = this.authService.getVersion();
  }

  login() {
    const formData: FormData = new FormData();
    formData.append('username', this.model.username);
    formData.append('password', this.model.password);
    this.authService.login(formData).subscribe(response => this.showEsito(response, 'Login'));
  }

  private showEsito(outcome: Outcome<User>, operation: string) {
    if (outcome.status === Constants.FAILURE) {
      this.show = true;
      this.responseMessage = outcome.message;
    } else {
      this.translate.use((JSON.parse(sessionStorage.getItem('currentUser')) as User).language);
      if ((JSON.parse(sessionStorage.getItem('currentUser')) as User).isPwdReset === "Y") {
        const ref = this.dialogService.open(ChangePasswordComponent, {
            data: {
              username: (JSON.parse(sessionStorage.getItem('currentUser')) as User).username
            },
            header: '',
            width: '70%'
        });
        ref.onClose.subscribe(() => {
          this.route.navigate(['/home']);
        });
      } else
        this.route.navigate(['/home']);
    }
  }

  public passwordForgotten() {
    this.route.navigate(['/password/forgotten']);
  }

  public clearMessage() {
    this.show = false;
  }

  // loginOauth2() {
  //   window.location.href = "http://localhost:8080/sigma-est/oauth2/authorization/azure";
  // }

}
