import { Role } from './role.model';
import { SearchUser } from './search-user.model';
import { Injectable } from "@angular/core";

@Injectable()
export class User extends SearchUser {

    password: string;
    email = '';
    language = '';
    languageName: string;
    accountLocked = false;
    avatar = '';
    accountActivationDate = '';
    accountExpirationDate = '';
    defaultProjectCode = '';
    defaultProjectName = '';
    defaultStakeCode = '';
    defaultStakeName = '';
    defaultCountryCode = '';
    defaultCountryName = '';
    isPwdReset = 'N';
    roles: Role[] = [];
    rolesName = '';
}
