import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanDeactivate, RouterStateSnapshot} from '@angular/router';

@Injectable()
export class ConfirmDeactivateGuard implements CanDeactivate<any> {

  canDeactivate(target: any, route: ActivatedRouteSnapshot, currentState: RouterStateSnapshot) 
  {
    //if(target.hasChanges()){
    if(target.hasChanges(currentState.url)){
      return window.confirm('Are you sure you want to leave the page? Your progress will be lost.'); // TODO: use i18n
    }
    return true;
  }

}
