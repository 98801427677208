import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { TreeNode } from "primeng/api";
import { Observable } from "rxjs";
import { map, catchError } from 'rxjs/operators';

import { CommonService } from "src/app/common/common.service";

import { FileAttribute } from "src/app/model/file-attribute.model";
import { MapSummary } from "src/app/model/map-summary.model";
import { Outcome } from "src/app/model/outcome.model";


@Injectable()
export class DragService extends CommonService<FileAttribute>{

    constructor(protected httpClient: HttpClient) {
        super('map/drag', httpClient);
    }

    
    uploadMapping(formData: FormData, projectCode: string, activityName: string, stakeCode: string): Observable<Outcome<TreeNode[]>> {
        return this.httpClient
            .post(this.targetUrl + '/upload/file/' + projectCode + '?activityName=' + activityName + '&stakeCode=' + stakeCode, formData)
            .pipe(map((response) => response as Outcome<TreeNode[]>)
                      , catchError((error: any) => this.handleError(error))
                  );
    }

    getActivityTree(projectCode: string, activityName: string): Observable<Outcome<TreeNode[]>> {
        return this.httpClient
            .get(this.targetUrl + '/tree/get/' + projectCode + '?activityName=' + activityName )
            .pipe(map(response => response as Outcome<TreeNode[]>)
                    , catchError((error: any) => this.handleError(error))
                );
    }

    //	get the already mapped activity tree to be edited (right side of drag mapping)
    getMappedActivityTree(projectCode: string, activityName: string, stakeCode: string): Observable<Outcome<TreeNode[]>> {
        return this.httpClient
            .get(this.targetUrl + '/tree/get/map/' + projectCode + '?activityName=' + activityName + '&stakeCode=' + stakeCode )
            .pipe(map(response => response as Outcome<TreeNode[]>)
                    , catchError((error: any) => this.handleError(error))
                );
    }

    //	get the names of attributes values used to map the already mapped activity tree (left side of drag mapping)
    getUsedAttributeTree(projectCode: string, activityName: string, stakeCode: string): Observable<Outcome<TreeNode[]>> {
        return this.httpClient
            .get(this.targetUrl + '/tree/get/used/' + projectCode + '?activityName=' + activityName + '&stakeCode=' + stakeCode )
            .pipe(map(response => response as Outcome<TreeNode[]>)
                    , catchError((error: any) => this.handleError(error))
                );
    }

    autosaveDataTree(projectCode: string, idUpload: number, mapping: TreeNode[]): Observable<Outcome<null>>{
        return this.httpClient
            .post(this.targetUrl + '/tree/save/' + projectCode + '?idUpload=' + idUpload, JSON.stringify(mapping), this.httpOptions)
            .pipe(map((response) => response as Outcome<null>)
                    , catchError((error: any) => this.handleError(error))
                );
    }

    saveDataTree(projectCode: string, idUpload: number, mapping: TreeNode[]): Observable<Outcome<null>>{
        return this.httpClient
            .post(this.targetUrl + '/tree/save/' + projectCode + '?idUpload=' + idUpload, JSON.stringify(mapping), this.httpOptions)
            .pipe(map((response) => response as Outcome<null>)
                    , catchError((error: any) => this.handleError(error))
                );
    }

    clearStageTable(projectCode: string, idUpload: number): Observable<Outcome<null>> {
        return this.httpClient
            .post(this.targetUrl + '/tree/clear/' + projectCode + '?idUpload=' + idUpload, this.httpOptions)
            .pipe(map((response) => response as Outcome<null>)
                    , catchError((error: any) => this.handleError(error))
                );
    }

    getDragSummary(projectCode: string, idUpload: number): Observable<Outcome<MapSummary[]>> {
        return this.httpClient
        .get(this.targetUrl + '/tree/get/summary/' + projectCode + '?idUpload=' + idUpload )
        .pipe(map(response => response as Outcome<MapSummary[]>)
                , catchError((error: any) => this.handleError(error))
            );
    }

    moveTreeData(projectCode: string, idUpload: number, force: string, mapping: TreeNode[]){
        return this.httpClient
            .post(this.targetUrl + '/tree/move/' + projectCode + '?idUpload=' + idUpload + '&force=' + force, JSON.stringify(mapping), this.httpOptions)
            .pipe(map((response) => response as Outcome<null>)
                    , catchError((error: any) => this.handleError(error))
                );
    }

    generateSchema(projectCode: string, activityName: string, stakeCode: string, idUpload: number, prepare: string) {
        return this.httpClient
            .post(this.targetUrl + '/tree/generate/' + projectCode + '?activityName=' + activityName + '&stakeCode=' + stakeCode + '&idUpload=' + idUpload + '&prepare=' + prepare, this.httpOptions)
            .pipe(map((response) => response as Outcome<null>)
                    , catchError((error: any) => this.handleError(error))
                );
    }


    removeParentTreeNodeList(treeNodeList: TreeNode<any>[]): TreeNode<any>[] {
        treeNodeList.forEach( (treeNode) => {
            if (treeNode.parent !== null) 
                treeNode.parent = null
            if (treeNode.children !== undefined) {
                this.removeParentTreeNodeList(treeNode.children)
            }
        });
        return treeNodeList;
    }

    getParameterAutosaveEnabled() {
        return this.httpClient
            .get(this.targetUrl + '/getParameterAutosaveEnabled' )
            .pipe(map((response) => response as Outcome<any[]>)
                , catchError((error: any) => this.handleError(error))
                );
    }

    getParameterAutosaveTime() {
        return this.httpClient
            .get(this.targetUrl + '/getParameterAutosaveTime' )
            .pipe(map((response) => response as Outcome<any[]>)
                , catchError((error: any) => this.handleError(error))
                );
    }

    copyTreeNode(treeNodeList: TreeNode<any>[]): TreeNode<any>[] {
        let outputTree: TreeNode<any>[];
        //
        return outputTree;
    }
}