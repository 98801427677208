import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map, catchError } from "rxjs/operators";
import { CommonService } from "src/app/common/common.service";
import { GlobalFilterLazyLoadEvent } from "src/app/model/global-filter-lazyload-event.model";
import { Issue } from "src/app/model/issue.model";
import { Outcome } from "src/app/model/outcome.model";

@Injectable()
export class SupportService extends CommonService<Issue>{
    
    constructor(protected httpClient: HttpClient){
        super('support', httpClient);
    }


    getListIssues(tableOrder: GlobalFilterLazyLoadEvent, onlyOpened: string ):Observable<Outcome<Issue[]>> {
        let formData = new FormData();
        formData.append('tableOrder', JSON.stringify(tableOrder));
        return this.httpClient
                .post<Outcome<Issue[]>>(this.targetUrl + '/getListIssues?onlyOpened=' + onlyOpened, formData)
                .pipe(map(response => response as Outcome<null>)
                    , catchError((error: any) => this.handleError(error))
                );
    }

    getIssue(id: string): Observable<Outcome<Issue>> {
        return this.httpClient.get(this.targetUrl + '/getIssue/' + id )
                              .pipe(map(response => response as Outcome<Issue>)
                                        , catchError((error: any) => this.handleError(error))
                                    );
    }

}