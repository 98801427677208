import { Model } from './common.model';
import { Stakeholder } from './stakeholder.model';

export class NewsMessage extends Model {
	/* id: long
	   operation */
	title: string = '';
	content: string = '';
	type: number = null;
	flag: string = 'N';
	publishDate = '';

	listStakeholder: Stakeholder[] = [];
	project = '';
	projectCode = '';

}