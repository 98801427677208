import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { User } from 'src/app/model/user.model';
import { environment } from 'src/environments/environment';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from '../../login/login.service';
import { UserMessageService } from '../../admin/user/user-message.service';
import { UserService } from '../../admin/user/user.service';
import { Constants } from 'src/app/model/constants.model';

@Component({
  selector: 'app-top-menu',
  templateUrl: './top-menu.component.html'
})
export class TopMenuComponent implements OnInit {

  avatarPath: string;
  numberOfMessage: number;
  numberOfFileMessage: number;
  titleBellMessage: string;
  numberOfErrorMessage: number;
  mailto = environment.mailSupport;
  manualLink = '';
  
  constructor(private authService: AuthService, private route: Router, private userMessageService: UserMessageService, 
              private translate: TranslateService, private userService: UserService) { }

  ngOnInit() {
    this.avatarPath = 'assets/images/avatars/' + (JSON.parse(sessionStorage.getItem('currentUser')) as User).avatar + '.png';
   /* this.userService.getServiceMessageCount('').subscribe(response => {
      this.numberOfMessage = response.responseBody;
    });*/

    this.userMessageService.getFileMessageCount((JSON.parse(sessionStorage.getItem('currentUser')) as User).defaultProjectCode, (JSON.parse(sessionStorage.getItem('currentUser')) as User).defaultStakeCode).subscribe(response => {
      this.numberOfFileMessage = response.responseBody;
      if (this.numberOfFileMessage === 0) {this.titleBellMessage = this.translate.instant('title.bellNoMessage'); } else {this.titleBellMessage = this.translate.instant('title.bellMessage', { num: this.numberOfFileMessage}); }
    });
  /*  this.userService.getServiceMessageCount('ERROR').subscribe(response => {
      this.numberOfErrorMessage = response.responseBody;
      if (this.numberOfErrorMessage === 0) {this.titleBellMessage = this.translate.instant('title.bellNoMessage'); } else {this.titleBellMessage = this.translate.instant('title.envelopeMessage', { num: this.numberOfErrorMessage}); }
    });*/
    this.userService.getParameterManualLink().subscribe( outcome => {
      if (outcome.status === Constants.SUCCESS) this.manualLink = outcome.responseBody; 
  });
  }

  logout() {
    this.authService.logout().subscribe(response => this.route.navigate(['/login']));
  }

}
