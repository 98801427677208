import { map, catchError } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient} from '@angular/common/http';
import { DomainValue } from 'src/app/model/domain-value.model';
import { CommonService } from 'src/app/common/common.service';
import { Outcome } from 'src/app/model/outcome.model';

@Injectable()
export class DomainValueService extends CommonService<DomainValue> {

    constructor(protected httpClient: HttpClient) {
        super('domain/values', httpClient);
    }

    insertSingleDomainValue(formData: FormData): Observable<Outcome<null>> {
        return this.httpClient
            .post(this.targetUrl + '/insertsingledomainvalue', formData)
            .pipe(map((response) => response as Outcome<null>)
                      , catchError((error: any) => this.handleError(error))
                  );
    }


}
