import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Outcome } from 'src/app/model/outcome.model';
import { environment } from 'src/environments/environment';
import { map, catchError } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { Stakeholder } from 'src/app/model/stakeholder.model';
import { CommonService } from 'src/app/common/common.service';
import { Country } from 'src/app/model/country.model';

@Injectable()
export class StakeholderService extends CommonService<Stakeholder> {

    constructor(protected httpClient: HttpClient) {
        super('stakeholder', httpClient);
    }

    get(id: number): Observable<Outcome<Stakeholder>> {
        return this.httpClient
                   .get<Outcome<Stakeholder>>(this.targetUrl + '/get?id=' + id)
                   .pipe(map(response => response)
                        , catchError((error: any) => this.handleError(error))
                );
    }

    generateToken(formData: FormData): Observable<Outcome<null>> {
        return this.httpClient
            .post<Outcome<string>>(this.targetUrl + '/generateToken', formData)
            .pipe(map((response) => response as Outcome<null>)
                      , catchError((error: any) => this.handleError(error))
                  );
    }

    listCountry(name: string): Observable<Outcome<Country[]>> {
        return this.httpClient
                .get<Outcome<Country[]>>(this.targetUrl + '/country/lov?name=' + name)
                .pipe(map(response => response)
                    , catchError((error: any) => this.handleError(error))
                );
    }

}
