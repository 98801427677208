import { Component, OnInit } from "@angular/core";
import { Constants } from "src/app/model/constants.model";
import { NewsMessage } from "src/app/model/news-message.model";
import { User } from "src/app/model/user.model";
import { ControlPanelService } from "../admin/controlPanel/controlPanel.service";
import { UserService } from "../admin/user/user.service";

@Component({
    selector: 'app-welcome',
    templateUrl: 'welcome.component.html'
})
export class WelcomeComponent implements OnInit {

    connectedUser: User;
    newsList: NewsMessage[];
    noNews: boolean = true;
    alertList: NewsMessage[];
    noAlerts: boolean = true;

    constructor(private userService: UserService) { }

    
    ngOnInit(): void {
        this.connectedUser = JSON.parse(sessionStorage.getItem('currentUser')) as User;
        this.getLists();
    }

    getLists() {
        this.getALLAlertMessageUser('NEW');
        this.getALLAlertMessageUser('MSG');
    }

    getALLAlertMessageUser(type: string) {
        this.userService.getALLAlertMessageUser(type, this.connectedUser.defaultProjectCode).subscribe(outcome => {
            if(outcome.status === Constants.SUCCESS){

                if (outcome.responseBody.length !== 0 && type == 'NEW'){
                    this.newsList = outcome.responseBody;
                    this.noNews = false;
                }
                if (outcome.responseBody.length !== 0 && type == 'MSG'){
                    this.alertList = outcome.responseBody;
                    this.noAlerts = false;
                }
                    
            } 
        });
    }
}