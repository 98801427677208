import { OnInit, Component, OnDestroy } from '@angular/core';
import { UserProfileService } from './user-profile.service';
import { Subscription } from 'rxjs';
import { User } from 'src/app/model/user.model';


@Component({
    selector: 'app-user-info',
    templateUrl: './user-info.component.html'
  })

  export class UserInfoComponent implements OnInit, OnDestroy {

    subscription: Subscription;
    connectedUser: User;

    constructor(private userProfileService: UserProfileService) {
        this.subscription = userProfileService.profile$.subscribe( user =>
          this.connectedUser = user);
    }

    ngOnInit(): void {
         this.connectedUser = JSON.parse(sessionStorage.getItem('currentUser')) as User;
    }

   ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
