import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { CommonService } from 'src/app/common/common.service';
import { FileMessage } from 'src/app/model/file-message.model ';
import { GlobalFilterLazyLoadEvent } from 'src/app/model/global-filter-lazyload-event.model';
import { Outcome } from 'src/app/model/outcome.model';
import { ServiceMessage } from 'src/app/model/service-message.model';
import { User } from 'src/app/model/user.model';
import { environment } from 'src/environments/environment';

@Injectable()
export class UserMessageService extends CommonService<User> {

    constructor(protected httpClient: HttpClient) {
        super('users', httpClient);
    }

    getFileMessageCount(projectCode: string, stakeCode: string): Observable<Outcome<any>> {
        return this.httpClient
                .get<Outcome<any>>(environment.apiUrl + '/account/getFileMessageCountTooltip?code=' + projectCode + "&stakeCode=" + stakeCode)
                .pipe(map(response => response)
                    , catchError((error: any) => this.handleError(error))
              );
    }

    getFileMessage(projectCode: string, stakeCode: string, tableOrder: GlobalFilterLazyLoadEvent): Observable<Outcome<FileMessage[]>> {
        let formData = new FormData();
        formData.append('tableOrder', JSON.stringify(tableOrder));
        return this.httpClient
                .post<Outcome<any>>(environment.apiUrl + "/account/getFileMessages?code=" + projectCode + "&stakeCode=" + stakeCode, formData)
                .pipe(map(response => response)
                    , catchError((error: any) => this.handleError(error))
              );
    
    }

    getServiceMessageCount(status: string): Observable<Outcome<any>> {
        return this.httpClient
                .get<Outcome<any>>(environment.apiUrl + '/account/getServiceMessages/count?status=' + status)
                .pipe(map(response => response)
                    , catchError((error: any) => this.handleError(error))
              );
    }

    getServiceMessage(numberOfPage: number): Observable<Outcome<ServiceMessage[]>> {
        return this.httpClient
                .get<Outcome<any>>(environment.apiUrl + '/account/getServiceMessages?numberOfPage=' + numberOfPage)
                .pipe(map(response => response)
                    , catchError((error: any) => this.handleError(error))
              );
    }

    downloadLog(id: number, stakeCode: string): Observable<any> {
        return this.httpClient
                   .get(environment.apiUrl + '/account/message/log/get/' + id + "?stakeCode=" + stakeCode,  this.httpOptions)
                   .pipe(map(response => response as Outcome<string>)
            , catchError((error: any) => this.handleError(error))
        );
    }

    downloadLogFile(id:number, stakeCode: string): any {
        const httpOptions = {
                                'responseType'  : 'arraybuffer' as 'json'
                            };
        return this.httpClient
                   .get(environment.apiUrl + '/account/message/log/file/get/' + id + "?stakeCode=" + stakeCode,  httpOptions);
    }

    downloadOriginalFile(id:number, stakeCode: string): any {
        const httpOptions = {
                                'responseType'  : 'arraybuffer' as 'json'
                            };
        return this.httpClient
                   .get(environment.apiUrl + '/account/message/file/get/' + id + "?stakeCode=" + stakeCode,  httpOptions);
    }
    
    downloadTranslateFile(id: string, stakeCode: string): Observable<any> {
        const httpOptions = {
            'responseType'  : 'arraybuffer' as 'json'
        };
        return this.httpClient
                   .get(environment.apiUrl + '/account/message/file/get/converted/' + id + "?stakeCode=" + stakeCode,  httpOptions);
    }
    
    mergeFiles(projectCode: string, activityName: string, stakeCode: string, outputType: string, fileIds: number[]): Observable<Outcome<null>> {
        return this.httpClient
                    .post(environment.apiUrl + '/account/message/files/merge/' + projectCode + "?activityName=" + activityName + "&stakeCode=" + stakeCode + "&outputType=" + outputType, fileIds)
                    .pipe(map((response) => response as Outcome<null>)
                      , catchError((error: any) => this.handleError(error))
                  );
    }
    
    //REMOVE_OUTPUT_TYPE
    getRemoveOutputType() {
        return this.httpClient
            .get(environment.apiUrl + '/account/getRemoveOutputType' )
            .pipe(map((response) => response as Outcome<any[]>)
                , catchError((error: any) => this.handleError(error))
                );
    }

    setMessageAsRead(id: number): Observable<any> {
        return this.httpClient
                   .post(environment.apiUrl + '/services/rest/message/setMessageAsRead/' + id,  null, this.httpOptions)
                   .pipe(map(response => response as Outcome<string>)
            , catchError((error: any) => this.handleError(error))
        );
    }

    startElaboration(id: number): Observable<any> {
        return this.httpClient
                .post(environment.apiUrl + '/scheduler/start/' + id , null, this.httpOptions)
                .pipe(map(response => response as Outcome<null>)
                    , catchError((error: any) => this.handleError(error))
      );
    }

    isSchedulerRunning(): Observable<any> {
        return this.httpClient
            .get(environment.apiUrl + '/scheduler/isRunning');
    }

}