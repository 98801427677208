import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router, NavigationEnd } from '@angular/router';
import { SwUpdate } from '@angular/service-worker';
import { TranslateService } from '@ngx-translate/core';
import { environment } from 'src/environments/environment';

@Component({
  // tslint:disable-next-line
  selector: 'body',
  template: '<router-outlet></router-outlet>'
})
export class AppComponent implements OnInit {

  constructor(private translate: TranslateService, private router: Router, private titleService: Title, private swUpdate: SwUpdate) {
    //translate.setDefaultLang(translate.getBrowserLang());
    translate.setDefaultLang("en");
    translate.use(translate.getBrowserLang());
    this.titleService.setTitle(environment.appName);
  }

  ngOnInit() {

    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0);
    });
    
    // check if new version is available
    if (this.swUpdate.isEnabled) {
      this.swUpdate.available.subscribe(() => {
          if(confirm(this.translate.instant('msg.newVersion'))) {
              window.location.reload();
          }
      });
  }   

  }
}
