import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { User } from 'src/app/model/user.model';

@Injectable()
export class UserProfileService {

    private changeProfileSource = new Subject<User>();

    profile$ = this.changeProfileSource.asObservable();

    setProfile(user: User) {
        this.changeProfileSource.next(user);
    }

    getProfile() {
        return sessionStorage.getItem('currentUser');
    }
}
