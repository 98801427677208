import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { CommonService } from 'src/app/common/common.service';
import { User } from 'src/app/model/user.model';
import { Role } from 'src/app/model/role.model';
import { Outcome } from 'src/app/model/outcome.model';
import { Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Stakeholder } from 'src/app/model/stakeholder.model';
import { ChangePassword } from './change-password.model';
import { Project } from 'src/app/model/project.model';
import { SearchUser } from 'src/app/model/search-user.model';
import { NewsMessage } from 'src/app/model/news-message.model';

@Injectable()
export class UserService extends CommonService<User> {

    constructor(protected httpClient: HttpClient) {
        super('users', httpClient);
    }

    roleList(): Observable<Outcome<Role[]>> {
        return this.httpClient
                .post<Outcome<Role[]>>(this.targetUrl + '/role/list', null)
                .pipe(map(response => response)
                    , catchError((error: any) => this.handleError(error))
            );
    }

    insertUser(user: User): Observable<Outcome<null>> {
        return this.httpClient
                .post(this.targetUrl + '/insert', JSON.stringify(user), this.httpOptions)
                .pipe(map(response => response as Outcome<null>)
                    , catchError((error: any) => this.handleError(error))
                );
    }

    updateUser(user: User): Observable<Outcome<null>> {
        return this.httpClient
                .post(this.targetUrl + '/update', JSON.stringify(user), this.httpOptions)
                .pipe(map(response => response as Outcome<null>)
                    , catchError((error: any) => this.handleError(error))
                );
    }

    resetPassword(changePassword: ChangePassword) {
        return this.httpClient
                .post(this.targetUrl + '/resetPassword', JSON.stringify(changePassword), this.httpOptions)
                .pipe(map(response => response as Outcome<null>)
                    , catchError((error: any) => this.handleError(error))
                );
    }

    listStakeHolder(username: string, type: string): Observable<Outcome<Stakeholder[]>> {
        return this.httpClient
                .get<Outcome<Stakeholder[]>>(environment.apiUrl + '/account/stakeholders/list?username=' + username + '&type=' + type)
                .pipe(map(response => response)
                    , catchError((error: any) => this.handleError(error))
              );
    }

    saveUserStakeHolders(username: string, stakeholders: Stakeholder[]): Observable<Outcome<null>> {
        return this.httpClient
                .post(this.targetUrl + '/stakeholders/save?username=' + username , JSON.stringify(stakeholders), this.httpOptions)
                .pipe(map(response => response as Outcome<null>)
                    , catchError((error: any) => this.handleError(error))
      );
    }

    listUser(code: string, type: string): Observable<Outcome<User[]>> {
        return this.httpClient
                .get<Outcome<User[]>>(this.targetUrl + '/stakeholder/list?stakeholderCode=' + code + '&type=' + type)
                .pipe(map(response => response)
                    , catchError((error: any) => this.handleError(error))
              );
    }

    saveStakeHolderUsers(code: string, users: SearchUser[]): Observable<Outcome<null>> {
        return this.httpClient
                .post(this.targetUrl + '/stakeholder/save?stakeholderCode=' + code , JSON.stringify(users), this.httpOptions)
                .pipe(map(response => response as Outcome<null>)
                    , catchError((error: any) => this.handleError(error))
      );
    }

    changePassword(changePassword: ChangePassword) {
        return this.httpClient
                .post(environment.apiUrl + '/account/password', JSON.stringify(changePassword), this.httpOptions)
                .pipe(map(response => response as Outcome<null>)
                    , catchError((error: any) => this.handleError(error))
                );
    }

    saveSettings(user: User) {
        return this.httpClient
                .post(environment.apiUrl + '/account/settings', JSON.stringify(user), this.httpOptions)
                .pipe(map(response => response as Outcome<null>)
                    , catchError((error: any) => this.handleError(error))
                );
    }

    listProject(username: string, stakeholderCode: string): Observable<Outcome<Project[]>> {
        return this.httpClient
                .get<Outcome<Project[]>>(environment.apiUrl + '/account/projects/lov?username=' + username + '&stakeholderCode=' + stakeholderCode)
                .pipe(map(response => response)
                    , catchError((error: any) => this.handleError(error))
              );
    }

    listStakeHolderProjects(username: string, stakeholderCode: string): Observable<Outcome<Project[]>> {
        return this.httpClient
                .get<Outcome<Project[]>>(environment.apiUrl + '/users/projects/lov?username=' + username + '&stakeholderCode=' + stakeholderCode)
                .pipe(map(response => response)
                    , catchError((error: any) => this.handleError(error))
              );
    }
    getMenu(): Observable<Outcome<any>> {
        return this.httpClient
                .get<Outcome<any>>(environment.apiUrl + '/account/getMenu')
                .pipe(map(response => response)
                    , catchError((error: any) => this.handleError(error))
              );
    }

    getAlertMessage(projectCode : string): Observable<Outcome<NewsMessage>> {
        return this.httpClient
                .get<Outcome<NewsMessage>>(environment.apiUrl + '/account/getAlertMessage?projectCode=' + projectCode)
                .pipe(map(response => response)
                    , catchError((error: any) => this.handleError(error))
              );
    }
    
    getALLAlertMessageUser(type: string, projectCode : string): Observable<Outcome<NewsMessage[]>> {
        return this.httpClient
            .get<Outcome<NewsMessage>>(environment.apiUrl + '/account/getALLAlertMessageUser?type=' + type + '&projectCode=' + projectCode )
                .pipe(map(response => response as Outcome<null>)
                    , catchError((error: any) => this.handleError(error))
                );
    }

    swithOffAlertMessage(msg: NewsMessage) {
        return this.httpClient
        .post(environment.apiUrl + '/account/swithOffAlertMessage', JSON.stringify(msg), this.httpOptions)
        .pipe(map(response => response as Outcome<null>)
            , catchError((error: any) => this.handleError(error))
        );
    }

    getParameterManualLink() {
        return this.httpClient.get(environment.apiUrl + '/account/getParameterManualLink' )
            .pipe(map((response) => response as Outcome<any[]>)
                , catchError((error: any) => this.handleError(error))
                );
    }
}
